<template>
    <div class="container">
      <!-- <transition
        name="change"
        mode="out-in"
        enter-active-class="animate__animated animate__fadeInRight animate__slow"
      > -->
        <!-- <keep-alive> -->
          <router-view></router-view>
        <!-- </keep-alive> -->
      <!-- </transition> -->
  
      <div class="nav-bar">
        <div class="img-list">
          <div
            v-for="(item, i) in arrayWithIndex.slice(start, end)"
            :key="i"
            @click="toggleScene(item.index, item.item.modelPath)"
            :class="index == item.index ? 'active-height' : ''"
          >
            <img
              :class="index == item.index ? 'top-img actived' : 'top-img'"
              :src="item.item.path"
              alt=""
            />
            <div
              :class="
               index ==  item.index ? 'img-title title-actived' : 'img-title'
              "
            >
              {{ item.item.title }}
            </div>
          </div>
          <img
            @click="back"
            class="arrow left"
            src="../../assets/hall/jt1.png"
            alt=""
          />
          <img
            @click="forward"
            class="arrow right"
            src="../../assets/hall/jt2.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>

  // import Product001 from './products/Product001.vue'
  export default {
    // components: { Product001 },
    name: "hall",
    data() {
      return {
        index: 0,
        start: 0,
        end: 4,
        selectIndex: 0,
        imgList: [
          {
            path: require("../../assets/hall/1.png"),
            title: "电源采集控制模块",
            modelPath: "/p1",
          },
          // {
          //   path: require("../../assets/hall/002.png"),
          //   title: "电源采集控制模块",
          //   modelPath: "p2",
          // },
          // {
          //   path: require("../../assets/hall/2-2.png"),
          //   title: "电源采集控制模块",
          //   modelPath: "p2-1",
          // },
          {
            path: require("../../assets/hall/2-1.png"),
            title: "电源采集控制模块",
            modelPath: "p2",
          },
          {
            path: require("../../assets/hall/003.png"),
            title: "电源采集控制模块",
            modelPath: "p3",
          },
          {
            path: require("../../assets/hall/3-1.png"),
            title: "电源采集控制模块",
            modelPath: "p3-1",
          },
          {
            path: require("../../assets/hall/004.png"),
            title: "电源一体化控制柜",
            modelPath: "p4",
          },
          {
            path: require("../../assets/hall/005.png"),
            title: "三电源箱",
            modelPath: "p5",
          },
          {
            path: require("../../assets/hall/006.png"),
            title: "三电源箱",
            modelPath: "p6",
          },
          {
            path: require("../../assets/hall/007.png"),
            title: "单路电机控制箱",
            modelPath: "p7",
          },
          {
            path: require("../../assets/hall/008.png"),
            title: "单路电机控制箱",
            modelPath: "p8",
          },
          {
            path: require("../../assets/hall/009.png"),
            title: "一用一备水泵控制箱",
            modelPath: "p9",
          },
          {
            path: require("../../assets/hall/010.png"),
            title: "一用一备水泵控制箱",
            modelPath: "p10",
          },
          {
            path: require("../../assets/hall/011.png"),
            title: "电源一体化控制柜",
            modelPath: "/p11",
          },
          {
            path: require("../../assets/hall/012.png"),
            title: "电源一体化控制柜",
            modelPath: "p12",
          },
          {
            path: require("../../assets/hall/013.png"),
            title: "电源一体化控制柜",
            modelPath: "p13",
          },
          {
            path: require("../../assets/hall/014.png"),
            title: "电源一体化控制柜",
            modelPath: "p14",
          },
          {
            path: require("../../assets/hall/015.png"),
            title: "电源一体化控制柜",
            modelPath: "p15",
          },
          {
            path: require("../../assets/hall/016.png"),
            title: "电源一体化控制柜",
            modelPath: "p16",
          },
        ],
        listLength: 0,
      };
    },
    computed: {
      arrayWithIndex() {
        return this.imgList.map((item, index) => {
          return { index, item };
        });
      },
    },
    methods: {
      toggleScene(i, path) {
        console.log('click')
        localStorage.setItem("3dIndex", this.index);
        localStorage.setItem("start", this.start);
        localStorage.setItem("end", this.end);
        this.index = i;
        this.$router.push(path);
        // if(this.end==this.listLength){
        //     this.end = this.end-2;
        //     this.start =this.start-2
        // }
        let ind = this.index-4;
        if(this.index<4){
          this.start=0;
          this.end=4
        }else{
          
          this.start = ind+1;
          this.end=ind+5;
        }
        console.log("index：" + this.index);
        console.log("end:" + this.end);
        console.log("start:" + this.start);
      },
      forward() {     
      
        if (this.index < 3) {
          this.index++;
          this.$router.push(this.imgList[this.index].modelPath);
        } 
        else {
          if (this.end < this.listLength ) {
            this.index++;
            this.start++;
            this.end++;
            this.$router.push(this.imgList[this.index].modelPath);
          }else{
              return
          }
        }
        localStorage.setItem("3dIndex", this.index);
        localStorage.setItem("start", this.start);
        localStorage.setItem("end", this.end);
        console.log('forward');
        console.log("index：" + this.index);
        console.log("end:" + this.end);
        console.log("start:" + this.start);
      },
      back() {
       
        if (this.start > 0) {
          this.start--;
          this.end--;
          this.index--;
          this.$router.push(this.imgList[this.index].modelPath);
        } else {
          if (this.index > 0) {
            this.index--;
            this.$router.push(this.imgList[this.index].modelPath);
          } else {
            return;
          }
        }
        localStorage.setItem("3dIndex", this.index);
        localStorage.setItem("start", this.start);
        localStorage.setItem("end", this.end);
        console.log('back')
        console.log("index：" + this.index);
        console.log("end:" + this.end);
        console.log("start:" + this.start);
      },
    },
    mounted() {
      this.listLength = this.imgList.length;
      // console.log('3dindex:'+localStorage.getItem("3dIndex"))
    //   if(localStorage.getItem("3dIndex")){
    //       this.index = localStorage.getItem("3dIndex")
    //   }else{
    //       this.index = 0;
    //   }
    //   if(localStorage.getItem("start")){
    //       this.start = localStorage.getItem("start")
    //   }else{
    //       this.start = 0;
    //   }
    //   if(localStorage.getItem("end")){
    //       this.end = localStorage.getItem("end")
    //   }else{
    //       this.end = 4;
    //   }
     
    //   this.$router.push(this.imgList[this.index].modelPath);
    },
  };
  </script>
  
  <style scoped>
  .container {
    position: relative;
  }
  .hall {
    width: 100%;
    display: block;
  }
  .nav-bar {
    width: 40%;
    height: 9vw;
    position: absolute;
    top: 80%;
    left: 30%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(43, 110, 216, 0.8); */
    background-color: rgba(0, 0, 0, 0.6);
  }
  .img-list {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 85%;
    overflow: hidden;
    /* position: absolute; */
    /* z-index: -10;l */
  }
  .img-list > div {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25%;
    height: 90%;
    display: flex;
  }
  .active-height {
    height: 95% !important;
  }
  .top-img {
    width: 90%;
    height: 75%;
  }
  .actived {
    width: 100%;
    height: 80%;
  }
  .img-title {
    width: 90%;
    height: 25%;
    text-align: center;
    line-height: 220%;
    background-color: #fff;
    font-size: 0.16rem;
  }
  .title-actived {
    width: 100%;
    height: 25%;
    color: #127bf2;
  }
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 0.3rem;
  }
  .left {
    left: 0.7%;
  }
  .right {
    right: 0.7%;
  }
  </style>
  